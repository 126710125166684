/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Menu} from 'src/components/layout/menu';
import {NavBar} from 'src/components/layout/navBar';
import {Button} from 'src/components/ui/button';
import {getSession} from 'src/shared/utilities/session';
import styles from './layout.module.scss';

const Layout = (props) => {
  const [session, setSession] = useState(null);
  const contentClasses = classNames(styles.contentContainer, {
    [styles.fullWidth]: props.fullWidth,
  });

  useEffect(() => {
    setSession(getSession());
  }, []);

  return (
    <div className={styles.layout}>
      <div className={styles.menuContainer}>
        <Menu />
      </div>
      <div className={contentClasses}>
        {!props.hideNav && <NavBar sessionData={session} />}
        {props.trialExpired &&
          <div className={styles.trialWarning}>
            <div className={styles.message}>
              Your free trial has run out.
            </div>
            <div className={styles.cta}>
              <Button success small to="/subscription/new">
                Upgrade now
              </Button>
            </div>
          </div>
        }
        <main>{props.children}</main>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

export const ContentWrapper = (props) => (
  <div className={styles.contentWrapper}>
    {props.children}
  </div>
);
