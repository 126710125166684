import classNames from 'classnames';
import React from 'react';
import styles from './card.module.scss';

export const Card = (props) => {
  const {className, skeleton, children, ...extras} = props;
  const classes = classNames(styles.card, className, {
    [styles.skeleton]: skeleton,
  });

  return (
    <div className={classes} {...extras}>
      {children}
    </div>
  );
};
