import {browser} from 'src/shared/utilities/environment';

export const setLocalStorageItem = (key, value) => {
  if (!localStorageAvailable()) return;
  localStorage.setItem(key, value);
};

export const getLocalStorageItem = (key) => {
  if (!localStorageAvailable()) return;
  return localStorage.getItem(key);
};

export const removeLocalStorageItem = (key) => {
  if (!localStorageAvailable()) return;
  localStorage.removeItem(key);
};

export const localStorageAvailable = () => {
  const available = browser();
  !available && console.error('The window object is not available. ' +
                              'Unable to utilize localStorage.');
  return available;
};
