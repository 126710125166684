import classNames from 'classnames';
import {Link} from 'gatsby';
import React, {useEffect, useState} from 'react';
import {
  IoIosPerson,
  IoIosHome,
  IoIosCheckmarkCircleOutline,
  IoIosOptions,
} from 'react-icons/io';
import {Button} from 'src/components/ui/button';
import {
  BlockIcon,
  CheckIcon,
  HelpIcon,
  HomeIcon,
  PersonIcon,
} from 'src/components/ui/icons';
import Logo from 'src/images/logo_light.svg';
import LogoWithText from 'src/images/logo_light_with_text.svg';
import {browser} from 'src/shared/utilities/environment';
import {getUser} from 'src/shared/utilities/session';
import styles from './navBar.module.scss';

const NavItem = (props) => {
  let active = false;

  if (browser()) {
    active = window.location.pathname.startsWith(props.url);
  }

  const classes = classNames(styles.navItem, props.className, {
    [styles.active]: active,
  });

  return (
    <div className={classes}>
      <Link to={props.url}>
        {props.children}
      </Link>
    </div>
  );
};

export const NavBar = (props) => {
  const [items, setItems] = useState(null);

  useEffect(() => {
    if (browser) {
      setItems(getUser() ? LoggedInItems : LoggedOutItems);
    }
  }, []);

  return (
    <div className={styles.navBar}>
      <div className={styles.navContent}>
        {items}
      </div>
    </div>
  );
};

const LoggedOutItems = (
  <div className={styles.loggedOut}>
    <Link className={styles.logoWrapper} to="/">
      <img src={LogoWithText} className={styles.logo} />
    </Link>
    <div className={styles.accountLinks}>
      <NavItem className={styles.login} url="/login">
        Log In
      </NavItem>
      <NavItem className={styles.signup} url="signup">
        <Button success small>Sign Up</Button>
      </NavItem>
    </div>
  </div>
);

const LoggedInItems = (
  <div className={styles.loggedIn}>
    <Link className={styles.logoWrapper} to="/journal">
      <img src={Logo} className={styles.logo} />
    </Link>
    <NavItem url="/journal">
      <HomeIcon
        primary={styles.primaryColor}
        secondary={styles.secondaryColor}
      />
      Journal
    </NavItem>
    <NavItem url="/todo">
      <CheckIcon
        primary={styles.primaryColor}
        secondary={styles.secondaryColor}
      />
      To Do
    </NavItem>
    <NavItem url="/projects">
      <BlockIcon
        primary={styles.primaryColor}
        secondary={styles.secondaryColor}
      />
      Projects
    </NavItem>
    <NavItem url="/help">
      <HelpIcon
        primary={styles.primaryColor}
        secondary={styles.secondaryColor}
      />
      Help
    </NavItem>
    <NavItem className={styles.mobileAccount} url="/account">
      <PersonIcon
        primary={styles.primaryColor}
        secondary={styles.secondaryColor}
      />
      Account
    </NavItem>
  </div>
);

NavBar.propTypes = {
};
