/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import {useStaticQuery, graphql} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import favicon16 from 'src/images/icons/favicon-16x16.png';
import favicon32 from 'src/images/icons/favicon-32x32.png';

/**
 * <head> tags
 */
function SEO({description, lang, meta, title, children}) {
  const {site} = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              title
              description
              author
            }
          }
        }
      `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const siteTitle = site.siteMetadata.title;
  const pageTitle = `${title ? title + ' - ' : ''}${siteTitle}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `viewport`,
          content: `width=device-width, initial-scale=1.0,
                    maximum-scale=1.0, user-scalable=no`,
        },
      ].concat(meta)}
      link={[
        {rel: 'icon', type: 'image/png', sizes: '16x16', href: `${favicon16}`},
        {rel: 'icon', type: 'image/png', sizes: '32x32', href: `${favicon32}`},
      ]}
    >
      {children}
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
