export const currentEnvironment = () => {
  const {hostname} = window.location;

  switch (hostname) {
    case 'hackerpaper.com':
    case 'www.hackerpaper.com':
      return 'production';
    case 'localhost':
    case '192.168.0.108':
      return 'development';
    default:
      throw new Error(
          `No environment is defined for this hostname: ${hostname}.`,
      );
  }
};

export const apiUrl = (environment) => {
  switch (environment.toLowerCase()) {
    case 'production':
      return 'https://api.hackerpaper.com';
    case 'development':
      return `http://${window.location.hostname}:8000`;
    default:
      throw new Error(
          `No API URL has been defined for this environment: ${environment}.`,
      );
  }
};

/**
 * Determines if the current action is being executed within a web browser.
 * @return {boolean}
 */
export const browser = () => {
  return typeof window !== 'undefined';
};
