import classNames from 'classnames';
import {Link} from 'gatsby';
import React, {useEffect, useState} from 'react';
import {
  BlockIcon,
  CheckIcon,
  HelpIcon,
  HomeIcon,
  PersonIcon,
} from 'src/components/ui/icons';
import LogoWithText from 'src/images/logo_light_with_text.svg';
import {browser} from 'src/shared/utilities/environment';
import styles from './menu.module.scss';

const NavItem = (props) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (browser()) {
      setActive(window.location.pathname.startsWith(props.url));
    }
  }, []);

  const classes = classNames(styles.navItem, props.className, {
    [styles.active]: active,
  });

  return (
    <div className={classes}>
      <Link to={props.url}>
        <div className={styles.icon}>
          {props.icon}
        </div>
        <div className={styles.text}>
          {props.text}
        </div>
      </Link>
    </div>
  );
};

export const Menu = (props) => {
  return (
    <div className={styles.menu}>
      <Link className={styles.logoWrapper} to="/journal">
        <img src={LogoWithText} />
      </Link>
      <NavItem
        url="/journal"
        icon={
          <HomeIcon
            primary={styles.primaryColor}
            secondary={styles.secondaryColor}
          />
        }
        text="Journal"
      />
      <NavItem
        url="/todo"
        icon={
          <CheckIcon
            primary={styles.primaryColor}
            secondary={styles.secondaryColor}
          />
        }
        text="To Do"
      />
      <NavItem
        url="/projects"
        icon={
          <BlockIcon
            primary={styles.primaryColor}
            secondary={styles.secondaryColor}
          />
        }
        text="Projects"
      />
      <NavItem
        url="/help"
        icon={
          <HelpIcon
            primary={styles.primaryColor}
            secondary={styles.secondaryColor}
          />
        }
        text="Help"
      />
      <NavItem
        url="/account"
        icon={
          <PersonIcon
            primary={styles.primaryColor}
            secondary={styles.secondaryColor}
          />
        }
        text="Account"
      />
      <footer className={styles.footer}>
          © {new Date().getFullYear()} Hacker Paper
      </footer>
    </div>
  );
};
