import axios from 'axios';
import Cookies from 'js-cookie';
import {apiUrl, currentEnvironment} from './utilities/environment';
import {getToken, removeUser} from './utilities/session';

const baseHeaders = {
  'Accept': 'application/json',
};

function redirect401(url) {
  const ignoredUrls = ['/login'];

  const ignoreThisUrl = ignoredUrls.some((ignored) => (
    url.includes(ignored)
  ));

  return !ignoreThisUrl;
}

const defaultInterceptor = (response) => response;

const errorInterceptor = (error) => {
  const {status} = error.response;
  const unauthorized = status === 401;
  const redirectToLogin = unauthorized && redirect401(error.request.responseURL);

  if (redirectToLogin) {
    removeUser();
    alert('Unauthorized: You are no longer logged in.');
    window.location = '/login';
  }

  return Promise.reject(error);
};

const requestInterceptor = (config) => {
  const headers = Object.assign({}, baseHeaders);
  config.headers = Object.assign(headers, config.headers || {});

  const token = getToken();
  if (token) {
    config.headers['Authorization'] = `Token ${token}`;
  }

  const csrfToken = Cookies.get('csrftoken');
  if (csrfToken) {
    config.headers['X-CSRFTOKEN'] = csrfToken;
  }

  return config;
};

export const api = (options = {}) => {
  const env = currentEnvironment();
  const baseUrl = apiUrl(env);
  const baseApi = axios.create({
    baseURL: baseUrl,
    timeout: 60000,
  });

  baseApi.interceptors.request.use(requestInterceptor);
  baseApi.interceptors.response.use(defaultInterceptor, errorInterceptor);

  return baseApi;
};

export const apiV2 = () => {
  throw new Error('Not implemented');
};
