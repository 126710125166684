import classNames from 'classnames';
import {Link} from 'gatsby';
import React from 'react';
import styles from './button.module.scss';

const Button = (props) => {
  const {
    className,
    primary,
    secondary,
    success,
    danger,
    small,
    large,
    link,
    block,
    to,
    leadingIcon,
    trailingIcon,
    ...extras
  } = props;

  const classes = classNames(
      styles.button,
      className,
      primary && styles.primary,
      secondary && styles.secondary,
      success && styles.success,
      danger && styles.danger,
      small && styles.small,
      large && styles.large,
      link && styles.link,
      block && styles.block,
  );

  const Element = to ? Link : `button`;

  return (
    <Element className={classes} to={to} {...extras}>
      <div className={styles.content}>
        {leadingIcon}
        {/* leadingIcon &&
        <div className={styles.leadingIcon}>{leadingIcon}</div>
        */}
        <div className={styles.children}>
          {props.children}
        </div>
        {/* trailingIcon &&
        <div className={styles.trailingIcon}>{trailingIcon}</div>
            */}
        {trailingIcon}
      </div>
    </Element>
  );
};

export {Button};
