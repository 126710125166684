import {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from 'src/shared/utilities/localStorage';

const currentUserKey = 'CURRENT_PLATFORM_USER';
const projectFilterKey = 'PROJECT_FILTER';

export const setUser = (username, session) => {
  setLocalStorageItem(currentUserKey, JSON.stringify({
    ...session,
    username,
  }));
};

export const getUser = () => {
  return getSession();
};

export const getSession = () => {
  const data = getLocalStorageItem(currentUserKey);
  try {
    return data ? JSON.parse(data) : null;
  } catch (e) {
    console.log('There was a problem parsing the session data:', data);
    throw e;
  }
};

export const removeUser = () => {
  removeLocalStorageItem(currentUserKey);
};

export const getToken = () => {
  const user = getUser();
  return user ? user.token : null;
};

export const destroySession = () => {
  removeUser();
  window.location = '/';
};

export const setSessionData = (key, value) => {
  const currentUser = getUser();
  setLocalStorageItem(currentUserKey, JSON.stringify({
    ...currentUser,
    [key]: value,
  }));
};

export const setProjectFilter = (selectedProjects) => {
  setLocalStorageItem(projectFilterKey, JSON.stringify(selectedProjects));
};

export const getProjectFilter = () => {
  const data = getLocalStorageItem(projectFilterKey);
  try {
    return data ? JSON.parse(data) : {};
  } catch (e) {
    console.log('There was a problem parsing the session data:', data);
    return {};
  }
};
